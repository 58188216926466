@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ::-webkit-scrollbar {
  width: 0px;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */

/* Estilos para a barra de rolagem */
::-webkit-scrollbar {
  width: 8px;
  /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Cor do fundo da trilha da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Cor do controle deslizante da barra de rolagem */
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Cor do controle deslizante da barra de rolagem quando hover */
}